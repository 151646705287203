var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"card-settings","disabled":_vm.loadingAreas,"loading":_vm.loadingAreas}},[_c('v-card-title',[_c('p',[_vm._v(_vm._s(_vm.$t('CardRoomSettings.title')))])]),_c('v-card-text',{staticClass:"settings"},[_c('v-row',[_c('v-col',[_c('v-row',[_c('label',{staticClass:"card_settings__mode__label"},[_vm._v(_vm._s(_vm.$t('CardRoomSettings.modeLabel')))]),_c('v-flex',{staticClass:"card_settings__mode__btns d-flex justify-space-around w-100"},[_c('v-btn',{attrs:{"id":"modeClassicBtn","text":_vm.gameSettings.modeSelected !==
                                    _vm.gameMode.CLASSIC,"rounded":"","outlined":""},on:{"click":function () { return _vm.setGameSettings({
                                        modeSelected: _vm.gameMode.CLASSIC,
                                    }); }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-map-marker ")]),_c('span',[_vm._v(_vm._s(_vm.$t('modes.classic')))])],1),_c('v-btn',{attrs:{"id":"modeCountryBtn","text":_vm.gameSettings.modeSelected !==
                                    _vm.gameMode.COUNTRY,"rounded":"","outlined":""},on:{"click":function () { return _vm.setGameSettings({
                                        modeSelected: _vm.gameMode.COUNTRY,
                                    }); }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-flag ")]),_c('span',[_vm._v(_vm._s(_vm.$t('modes.country')))])],1)],1)],1),_c('v-row',[_c('label',{staticClass:"card_settings__time__label"},[_vm._v(_vm._s(_vm.$t('CardRoomTime.title')))]),_c('TimePicker',{attrs:{"value":_vm.gameSettings.time},on:{"input":function (time) { return _vm.setGameSettings({ time: time }); }}})],1),_c('v-row',{staticClass:"card_settings__allow_btns d-flex justify-space-around flex-row",attrs:{"align":"center"}},[_c('div',[_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.zoomControl,"label":_vm.$t('CardRoomSettings.allowZoom'),"hide-details":""},on:{"change":function (zoomControl) { return _vm.setGameSettings({ zoomControl: zoomControl }); }}}),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.moveControl,"label":_vm.$t('CardRoomSettings.allowMove'),"hide-details":""},on:{"change":function (moveControl) { return _vm.setGameSettings({ moveControl: moveControl }); }}}),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.panControl,"label":_vm.$t('CardRoomSettings.allowPan'),"hide-details":""},on:{"change":function (panControl) { return _vm.setGameSettings({ panControl: panControl }); }}}),_c('br'),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.allPanorama,"label":_vm.$t('CardRoomSettings.includePhotopheres'),"hide-details":""},on:{"change":function (allPanorama) { return _vm.setGameSettings({ allPanorama: allPanorama }); }}}),_c('br'),_c('v-list-group',{attrs:{"prepend-icon":"mdi-cog"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" More settings ")])]},proxy:true}])},[(
                                    _vm.gameSettings.modeSelected ===
                                        _vm.gameMode.CLASSIC
                                )?_c('v-select',{attrs:{"label":_vm.$t('CardRoomSettings.scoreModeLabel'),"input-value":_vm.gameSettings.scoreMode,"items":_vm.scoreModes},on:{"change":function (scoreMode) { return _vm.setGameSettings({ scoreMode: scoreMode }); }}}):_vm._e(),_c('v-autocomplete',{attrs:{"label":_vm.$t('CardRoomSettings.selectAreas'),"value":_vm.gameSettings.areaParams,"items":_vm.optionsArea},on:{"input":function (areaParams) { return _vm.setGameSettings({ areaParams: areaParams }); }}})],1)],1),_c('div',[(!_vm.singlePlayer)?_c('v-text-field',{attrs:{"label":_vm.$t('CardRoomSettings.countDownLabel'),"value":_vm.gameSettings.countdown,"hide-details":"","type":"number"},on:{"input":function (countdown) { return _vm.setGameSettings({
                                        countdown: +countdown,
                                    }); }}}):_vm._e(),(
                                _vm.gameSettings.modeSelected !==
                                    _vm.gameMode.CLASSIC && !_vm.singlePlayer
                            )?_c('div',[_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.timeAttackSelected,"hide-details":""},on:{"change":function (timeAttackSelected) { return _vm.setGameSettings({
                                            timeAttackSelected: timeAttackSelected,
                                        }); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( 'CardRoomSettings.timeAttackLabel' ))+" "),_c('v-tooltip',{staticClass:"tooltip-timeattack",attrs:{"top":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,false,591445961)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'CardRoomSettings.timeattackInfos' )))])])]},proxy:true}],null,false,2680940937)})],1):_vm._e()],1)])],1),_c('v-col',{class:{
                    'd-none': !_vm.loadingGeoJson && !_vm.placeGeoJson,
                }},[_c('GmapMap',{ref:"mapRef",class:{ 'd-none': _vm.loadingGeoJson },staticStyle:{"width":"350px","max-width":"100%","height":"250px"},attrs:{"center":{ lat: 10, lng: 10 },"zoom":1,"map-type-id":"roadmap","options":{
                        gestureHandling: 'greedy',
                    }}}),(_vm.loadingGeoJson)?_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"width":"100%","height":"250px"},attrs:{"type":"image"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"dark":"","depressed":"","color":"#FF5252"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"id":"btnNextSettings","depressed":"","color":"#43B581","disabled":_vm.loadingGeoJson},on:{"click":_vm.onClickNext}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }